import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './ProductsMore.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
import { Link as Anchor } from "react-router-dom";
import moneda from '../moneda';
import { useMediaQuery } from '@react-hook/media-query';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function ProductsMore() {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const categoriasRefs = useRef([]);
    const categoriasInputRef = useRef(null);
    const swiperRef = useRef(null);
    const [productos, setProductos] = useState([]);

    const isScreenLarge = useMediaQuery('(min-width: 900px)');


    useEffect(() => {
        cargarProductos();
        cargarCategorias();


    }, []);



    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos.slice(0, 10));
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarCategorias = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };



    return (
        <div className='ProductsMore'>
            {loading ? (
                <div className='categoriSection'>     <ProductosLoading /></div>
            ) : (
                <div >
                    <div className='categoriSection'>

                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            slidesPerView={'auto'}
                            id='swiper_container_productsMore'
                            autoplay={{ delay: 3000 }}
                        >
                            {productos?.filter(item => item.masVendido === "si").map(item => (
                                <SwiperSlide key={item.idProducto} id='SwiperSlide-scroll-products-masvendidos'>
                                    <Anchor className='cardProdcutmasVendido' to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                        <img src={obtenerImagen(item)} alt="imagen" />
                                        <h6 className='masVendido'>Más Vendido</h6>
                                        <div className='cardText'>
                                            <h4> {item.titulo}</h4>
                                            <span>{item.descripcion}</span>
                                            <div className='deFLexPrice'>
                                                <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                    <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                )}
                                            </div>
                                        </div>
                                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                    </Anchor>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    <Swiper id='swiperBg'>

                    </Swiper>
                </div>
            )}
        </div>
    );
}
