import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './ProductsAdd.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
import { Link as Anchor } from "react-router-dom";
import moneda from '../moneda';
import { useMediaQuery } from '@react-hook/media-query';
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function ProductsAdd() {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [productos, setProductos] = useState([]);

    const isScreenLarge = useMediaQuery('(min-width: 900px)');

    useEffect(() => {
        cargarProductos();
        cargarCategorias();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos?.reverse()?.slice(0, 10));
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarCategorias = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const obtenerImagenes = (item) => {
        const imagenes = [];
        if (item.imagen1) imagenes.push(item.imagen1);
        if (item.imagen2) imagenes.push(item.imagen2);
        if (item.imagen3) imagenes.push(item.imagen3);
        if (item.imagen4) imagenes.push(item.imagen4);
        return imagenes;
    };

    return (
        <div>
            {loading ? (
                <ProductosLoading />
            ) : (
                <div>
                    <div className='categoriSection'>
                        <div className='deFlexTitlesection'>
                            <h3>Recien agregados</h3>
                            <button>
                                Ver más         <FontAwesomeIcon icon={faAngleDoubleRight} />
                            </button>
                        </div>
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            slidesPerView={'auto'}
                            id='cardsProdcutRecienAgregado'
                            autoplay={{ delay: 3000 }}
                        >
                            {productos?.map(item => (
                                <SwiperSlide key={item.idProducto} id='cardProdcutRecienAgregado'>
                                    <Anchor className='cardProdcutRecienAgregado' to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                        <Swiper
                                            spaceBetween={10}
                                            autoplay={{ delay: 2500 }}
                                        >
                                            {obtenerImagenes(item).map((imagen, index) => (
                                                <SwiperSlide key={index}>
                                                    <img src={imagen} alt={`Imagen ${index + 1}`} />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <div className='cardText'>
                                            <h4>{item.titulo}</h4>
                                            <div className='deFLexPrice'>
                                                <h5>{moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                    <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                )}
                                            </div>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard2' />
                                        </div>
                                    </Anchor>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            )}
        </div>
    );
}
